import React from "react";
import Cta from "./CTA";

import RenderMarkdown from "./utilities/RenderMarkdown";
import numToEuroString from "../functions/numToEuroString";
import hasQueryParam from "../functions/hasQueryParam";

export default function PricingCard({
  price = 9999999.99,
  pricingCardObject,
  isOffer = false,
  offerCode = "",
  priceOffer = 9999999.99,
  priceOfferText = "",
  titleBorder = "border-brand-beige-40 border-4",
}) {
  // add voucher code to query param if isOffer=true, offerCode is present and ctaObject.linkUrl doesn't contain voucher code query param
  if (
    isOffer &&
    offerCode &&
    !hasQueryParam("voucher", pricingCardObject.ctaObject.linkUrl)
  ) {
    pricingCardObject.ctaObject.linkUrl = `${
      pricingCardObject.ctaObject.linkUrl
    }${
      pricingCardObject.ctaObject.linkUrl.includes("?") ? "&" : "?"
    }voucher=${offerCode}`;
  }

  return (
    <article
      className={`m-2 p-6 w-full sm:w-1/2 border border-brand-beige-40 shadow-xl rounded-3xl`}
    >
      {pricingCardObject.overline ? (
        <RenderMarkdown
          markdownContent={pricingCardObject.overline}
          className="text-center"
          whitespace="normal"
        />
      ) : (
        <br />
      )}
      <div className={`p-4 ${titleBorder}`}>
        <RenderMarkdown
          markdownContent={pricingCardObject.title}
          className="text-center"
          whitespace="normal"
        />
      </div>

      <RenderMarkdown
        markdownContent={`## **${numToEuroString(price)}** ${
          pricingCardObject.priceAdditive ? pricingCardObject.priceAdditive : ""
        }`}
        className={`m-4 text-center ${isOffer ? "line-through" : ""}`}
        whitespace="normal"
      />
      {isOffer && (
        <RenderMarkdown
          markdownContent={`## **${numToEuroString(priceOffer)}**`}
          className="m-4 text-center"
          whitespace="normal"
        />
      )}
      {/* priceText */}
      <RenderMarkdown
        markdownContent={isOffer ? priceOfferText : pricingCardObject.priceText}
        className="text-center"
      />
      {/* priceDescription */}
      <RenderMarkdown
        markdownContent={pricingCardObject.priceDescription}
        className="text-left"
      />
      <Cta ctaObject={pricingCardObject.ctaObject} />
    </article>
  );
}
