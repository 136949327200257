import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import HeroSectionWithVideo from "../components/HeroSectionWithVideo";
import Section from "../components/Section";
import BenefitsList from "../components/BenefitsList";
import ContentsList from "../components/ContentsList";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";
import Introduction from "../components/Introduction.js";
import FAQs from "../components/FAQs";
import Pitch from "../components/Pitch.js";
import Cta from "../components/CTA";
import PricingSimple from "../components/PricingSimple.js";
import Famous from "../components/Famous.js";

const TimLayoutSalesPageTemplate = ({ data }) => {
  return (
    <>
      <Layout
        seoObject={data.seoObject}
        imageObject={data.headerObject.imageObject}
      >
        <HeroSectionWithVideo
          className="bg-brand-beige-40"
          heroObject={data.heroObject}
        />
        <Section>
          <Famous />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown markdownContent={data.youKnowText} />
        </Section>
        <Section className="bg-brand-beige-40">
          <RenderMarkdown markdownContent={data.imagineText} />
        </Section>
        <Section>
          <Pitch pitchObject={data.pitchObject} />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown markdownContent={data.afterText} />
          <Cta ctaObject={data.firstCtaObject} />
        </Section>
        <HeroSectionWithVideo heroObject={data.videoTestimonalsObject} />
        <Section classNameInnerContainer="flex justify-around items-end flex-wrap space-y-5 sm:space-y-10">
          {data.reviewList.map((el) => (
            <PreviewCompatibleImage
              className="max-w-md w-full"
              imageObject={el.imageObject}
              loading="lazy"
            />
          ))}
        </Section>
        <Section className="bg-brand-beige-40">
          <BenefitsList
            benefitsObject={data.benefitsObject}
            classNameIcon="text-brand-green"
          />
        </Section>
        <Section id="anmelden">
          <PricingSimple pricingObject={data.pricingObject} />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown markdownContent={data.bonusText} />
        </Section>
        <Section className="!px-0 !sm:px-5" classNameInnerContainer="space-y-10 sm:space-y-20">
          <Introduction introductionObject={data.introductionObject} />
          <ContentsList
            contentsObject={data.teachersContentsObject}
            classNameCard="bg-white"
          />
        </Section>
        <Section className="bg-brand-beige-40">
          <RenderMarkdown markdownContent={data.criteriaText} />
          <Cta ctaObject={data.ctaObject2} />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown markdownContent={data.guaranteeText} />
        </Section>
        <Section className="bg-brand-beige-40">
          <FAQs faqsObject={data.faqsObject} />
        </Section>
        <Section>
          <RenderMarkdown markdownContent={data.finalText} />
          <Cta ctaObject={data.ctaObject3} />
        </Section>
      </Layout>
    </>
  );
};

const TimLayoutSalesPage = ({ data }) => {
  return <TimLayoutSalesPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default TimLayoutSalesPage;

export const pageQuery = graphql`
  query TimLayoutSalesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          subtitle
          video {
            cloudflareId
            hasGifThumbnail
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        youKnowText
        imagineText
        pitchObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          description
        }
        afterText
        firstCtaObject {
          linkText
          linkUrl
        }
        videoTestimonalsObject {
          title
          subtitle
          video {
            cloudflareId
            hasGifThumbnail
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        reviewList {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        benefitsObject {
          title
          list {
            description
            iconName
          }
        }
        pricingObject {
          title
          pricingBoxLeftObject {
            title
            price
            priceText
            priceDescription
            ctaObject {
              linkText
              linkUrl
            }
          }
          pricingBoxRightObject {
            title
            price
            priceText
            priceDescription
            ctaObject {
              linkText
              linkUrl
            }
          }
        }
        bonusText
        introductionObject {
          introductionText
          imageSubtext
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          signatureImageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        teachersContentsObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
          }
        }
        criteriaText
        ctaObject2 {
          linkText
          linkUrl
        }
        guaranteeText
        faqsObject {
          title
          qAndA {
            question
            answer
          }
        }
        finalText
        ctaObject3 {
          linkText
          linkUrl
        }
      }
    }
  }
`;
