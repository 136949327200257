import React from "react";

import PricingCard from "./PricingCard.js";
import RenderMarkdown from "./utilities/RenderMarkdown.js";

export default function PricingSimple({ pricingObject }) {
  return (
    <article>
      <div className="flex justify-center">
        <RenderMarkdown
          className="text-center"
          markdownContent={pricingObject.title}
        />
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-stretch">
        <PricingCard
          pricingCardObject={pricingObject.pricingBoxLeftObject}
          titleBorder = "border-brand-mint border-4"
          price={pricingObject.pricingBoxLeftObject.price}
        />
        <PricingCard
          pricingCardObject={pricingObject.pricingBoxRightObject} 
          price={pricingObject.pricingBoxRightObject.price}

        />
      </div>
    </article>
  );
}
